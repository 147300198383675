import React from 'react';
import { graphql } from 'gatsby';
import { Styled, css } from 'theme-ui';  // Theme UI for consistent styling

// Using the theme's layout, if available, or a generic layout component
// Adjust the import path based on your directory structure
import Layout from '@narative/gatsby-theme-novela/src/components/Layout';

const LandingPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <div css={css({ maxWidth: '800px', margin: '0 auto', padding: '20px' })}>
        <Styled.h1>{frontmatter.title}</Styled.h1>
        <Styled.p>{frontmatter.description}</Styled.p>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export default LandingPage;

export const pageQuery = graphql`
  query LandingPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/landingPage.md/" }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
